import axios from 'axios'
// import api from '../api'

const defaultState = {
  status: '',
  token: sessionStorage.getItem('token') || '',
  username: sessionStorage.getItem('username') || '',
  userFullName: sessionStorage.getItem('userFullName') || '',
  loginErrorMessage: '',
}

const actions = {
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios({ url: '/user/authenticate', data: user, method: 'POST' })
        .then((response) => {
          const { token } = response.data.data
          const { username } = response.data.data
          const { name } = response.data.data
          sessionStorage.setItem('token', token)
          sessionStorage.setItem('username', username)
          sessionStorage.setItem('userFullName', name)
          axios.defaults.headers.common.Authorization = `Bearer ${token}`
          commit('auth_success', { token, username, name })
          resolve(response)
        })
        .catch((error) => {
          const loginErrorMessage = error.response.data.message
          commit('auth_error', { loginErrorMessage })
          sessionStorage.removeItem('token')
          reject(error)
        })
    })
  },
  logout({ commit }) {
    return new Promise((resolve) => {
      commit('logout')
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('username')
      sessionStorage.removeItem('userFullName')
      delete axios.defaults.headers.common.Authorization
      resolve()
    })
  },
}

const mutations = {
  auth_request(state) {
    // eslint-disable-next-line no-param-reassign
    state.status = 'loading'
  },
  auth_success(state, { token, username, name }) {
    // eslint-disable-next-line no-param-reassign
    state.status = 'success'
    // eslint-disable-next-line no-param-reassign
    state.token = token
    // eslint-disable-next-line no-param-reassign
    state.username = username
    // eslint-disable-next-line no-param-reassign
    state.userFullName = name
    // eslint-disable-next-line no-param-reassign
    state.loginErrorMessage = ''
  },
  auth_error(state, { loginErrorMessage }) {
    // eslint-disable-next-line no-param-reassign
    state.status = 'error'
    // eslint-disable-next-line no-param-reassign
    state.loginErrorMessage = loginErrorMessage
  },
  logout(state) {
    // eslint-disable-next-line no-param-reassign
    state.status = ''
    // eslint-disable-next-line no-param-reassign
    state.token = ''
    // eslint-disable-next-line no-param-reassign
    state.username = ''
    // eslint-disable-next-line no-param-reassign
    state.userFullName = ''
    // eslint-disable-next-line no-param-reassign
    state.loginErrorMessage = ''
  },
}

const getters = {
  isLoggedIn: state => !!state.token,
  isLoginError: state => (state.status === 'error'),
  isLoginLoading: state => (state.status === 'loading'),
  loginErrorMessage: state => state.loginErrorMessage,
  authStatus: state => state.status,
  userFullName: state => state.userFullName,

}

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
}
