import Vue from 'vue'
import Vuex from 'vuex'
import olt from './modules/olt'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    olt,
    user,
  },
})
