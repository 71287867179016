// import axios from 'axios'
import api from '../api'

const defaultState = {
  olts: [],
  totalOlts: 0,
  isLoading: false,
}

const actions = {
  // getOlts: (context) => {
  //   getOlts
  //     .then((response) => {
  //       context.commit('OLTS_UPDATED', response)
  //     })
  //     .catch((error) => {
  //       // eslint-disable-next-line
  //       console.error(error);
  //     })
  // },

  // getOlts({ commit }, queryParameter) {
  //   return new Promise((resolve) => {
  //     axios.get('/olt', queryParameter).then((response) => {
  //       const olts = response.data.docs
  //       const totalOlts = response.data.total
  //       commit('OLTS_UPDATED', { olts, totalOlts })
  //       resolve()
  //     })
  //   })
  // },

  getOlts({ commit }, queryParameter) {
    commit('olts_loading')
    return new Promise((resolve) => {
      api.getOlts(queryParameter).then((response) => {
        const olts = response.data.docs
        const totalOlts = response.data.totalDocs
        commit('olts_updated', { olts, totalOlts })
        resolve()
      })
    })
  },
}

const mutations = {
  olts_updated: (state, { olts, totalOlts }) => {
    // eslint-disable-next-line no-param-reassign
    state.olts = olts
    // eslint-disable-next-line no-param-reassign
    state.totalOlts = totalOlts
    // eslint-disable-next-line no-param-reassign
    state.isLoading = false
  },
  olts_loading: (state) => {
    // eslint-disable-next-line no-param-reassign
    state.isLoading = true
  },
}

const getters = {
  olts: state => state.olts,
  totalOlts: state => state.totalOlts,
  isLoading: state => state.isLoading,
}

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
}
