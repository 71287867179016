import axios from 'axios'

export default {
  async getOlts(queryParameter) {
    const olts = await axios.get('/olt', queryParameter)
    return olts
  },
  async getOnus(queryParameter) {
    const onus = await axios.get('/onu', queryParameter)
    return onus
  },
}
