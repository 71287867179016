import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuetify from 'vuetify'
import VueClipboard from 'vue-clipboard2'
import axios from 'axios'
import App from './App.vue'
import router from './router/router'
import store from './store'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'
// import 'material-design-icons-iconfont/dist/material-design-icons.css';


VueClipboard.config.autoSetContainer = true
Vue.use(VueRouter)
Vue.use(VueClipboard)

const opts = {
  icons: {
    iconfont: 'mdi',
  },
  options: {
    customProperties: true,
  },
}

Vue.use(Vuetify)

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
axios.defaults.headers.common.Authorization = `Bearer ${store.state.user.token}`

new Vue({
  vuetify: new Vuetify(opts),
  created() {
    document.title = 'TOT Operation Platform'
  },
  render: h => h(App),
  router,
  store,
}).$mount('#app')
