import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'main',
      component: () => import('../components/Main.vue'),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '/home',
          name: 'home',
          component: () => import('../components/Home.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('../components/Dashboard.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/olt',
          name: 'oltList',
          component: () => import('../components/OltList.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/olt/:id',
          name: 'oltDetail',
          component: () => import('../components/OltDetail.vue'),
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: '/onu',
          name: 'onuList',
          component: () => import('../components/OnuList.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/onu/:id',
          name: 'onuDetail',
          component: () => import('../components/OnuDetail.vue'),
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: '/onuconfig/zteonuconfig',
          name: 'zteonuconfig',
          component: () => import('../components/GenerateZTEONUConfig.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/onuconfig/huaweionuconfig',
          name: 'huaweionuconfig',
          component: () => import('../components/Contact.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/onuconfig/fiberhomeonuconfig',
          name: 'fiberhomeonuconfig',
          component: () => import('../components/Contact.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/oltconfig/zteoltconfig',
          name: 'zteoltconfig',
          component: () => import('../components/GenerateZTEOLTConfig.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/contact',
          name: 'contact',
          component: () => import('../components/Contact.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/pondownlist',
          name: 'ponDownList',
          component: () => import('../components/PonDownList.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/oltdownlist',
          name: 'oltDownList',
          component: () => import('../components/OltDownList.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/oltinterfaceoperstatusdownalarmlist',
          name: 'oltInterfaceOperStatusDownAlarmList',
          component: () => import('../components/OltInterfaceOperStatusDownAlarmList.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/oltstatusdownalarmlist',
          name: 'oltStatusDownAlarmList',
          component: () => import('../components/OltStatusDownAlarmList.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/oltinterfacetable/:id/oltinterfacetraffic',
          name: 'oltInterfaceTrafficDetail',
          component: () => import('../components/OltInterfaceTrafficDetail.vue'),
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../components/Login.vue'),
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('../components/Login.vue'),
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    // if (!store.getters.isLoggedIn) {
    if (!store.getters.isLoggedIn) {
      next({ name: 'login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router
